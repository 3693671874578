<template>
  <div class="epidemicPrevent">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="formType"
          v-model="searchParam.formsType"
          @change="$refs.list.search()"
          label="表单类型"
        />
        <v-input label="表单名称" v-model="searchParam.formsName" />
        <v-select
          clearable
          :options="formPostType"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="是否发布"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button text="提交列表" type="text" @click="toPostList(scope.row)" />
        <v-button text="下载二维码" type="text" @click="toUpload(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { epidemicPreventUrl, epidemicDeleteUrl } from "./api.js";
import { formType, formTypeMap, formPostType, formPostTypeMap } from "./map.js";
import { downloadHelper } from "@/utils/func.js";

export default {
  name: "epidemicPrevent",
  data() {
    return {
      formPostType,
      formType,
      searchParam: {
        formsName: "",
        formsType: "",
        status: "",
        tenantId: "",
      },

      tableUrl: epidemicPreventUrl,
      headers: [
        {
          prop: "formsType",
          label: "表单类型",
          formatter: (row, prop) => {
            return formTypeMap[row[prop]] || "--";
          },
        },
        {
          prop: "formsName",
          label: "表单名称",
        },
        {
          prop: "status",
          label: "是否发布",
          formatter: (row, prop) => {
            return formPostTypeMap[row[prop]] || "--";
          },
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.searchParam.tenantId = this.$store.state.app.userInfo.tenantId;
  },
  mounted() {},
  methods: {
    toUpload(row) {
      let config = {
        src: row.qrCode,
        fileName: row.formsName + ".png",
      };
      downloadHelper.downloadBySrc(config);
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          formsId: item.formsId,
        };
        this.$axios
          .post(`${epidemicDeleteUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toPostList(row) {
      this.$router.push({
        name: "epidemicPostList",
        query: {
          formsId: row.formsId,
        },
      });
    },
    toAdd() {
      this.$router.push({
        name: "epidemicPreventForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "epidemicPreventForm",
        query: { id: row.formsId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.epidemicPrevent {
  box-sizing: border-box;
  height: 100%;
}
</style>
